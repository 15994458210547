import Navbar from './Navbar';
import Footer from './Footer';
import { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import axios from 'axios';
import Wpp from './Wpp';

function Calculator() {
  const [income, setIncome] = useState('');
  const [filingStatus, setFilingStatus] = useState('single');
  const [deductions, setDeductions] = useState('');
  const [taxOwed, setTaxOwed] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incomeType, setIncomeType] = useState('individual'); // New state for income type

  const taxBrackets = {
    single: [
      { rate: 0.10, min: 0, max: 11000 },
      { rate: 0.12, min: 11001, max: 44725 },
      { rate: 0.22, min: 44726, max: 95375 },
      { rate: 0.24, min: 95376, max: 182100 },
      { rate: 0.32, min: 182101, max: 231250 },
      { rate: 0.35, min: 231251, max: 578125 },
      { rate: 0.37, min: 578126, max: Infinity },
    ],
    married: [
      { rate: 0.10, min: 0, max: 22000 },
      { rate: 0.12, min: 22001, max: 89450 },
      { rate: 0.22, min: 89451, max: 190750 },
      { rate: 0.24, min: 190751, max: 364200 },
      { rate: 0.32, min: 364201, max: 462500 },
      { rate: 0.35, min: 462501, max: 693750 },
      { rate: 0.37, min: 693751, max: Infinity },
    ],
    business: [
      { rate: 0.10, min: 0, max: 15000 },
      { rate: 0.15, min: 15001, max: 50000 },
      { rate: 0.25, min: 50001, max: 100000 },
      { rate: 0.34, min: 100001, max: 335000 },
      { rate: 0.39, min: 335001, max: 10000000 },
      { rate: 0.35, min: 10000001, max: Infinity },
    ],
  };

  const standardDeductions = {
    single: 13850,
    married: 27700,
    business: 0, // No standard deduction for business
  };

  const calculateTax = () => {
    setLoading(true);
    
    // Ensure deductions are treated as a number
    const deductionAmount = parseFloat(deductions) || 0;
    let taxableIncome = parseFloat(income) - deductionAmount;

    if (incomeType === 'expat') {
      if (taxableIncome <= 120000) {
        setTaxOwed(0);
        setLoading(false);
        return;
      } else {
        return calculateTaxForIncome('individual', taxableIncome);
      }
    }

    if (taxableIncome <= 0) {
      setTaxOwed(0);
      setLoading(false);
      return;
    }

    return calculateTaxForIncome(incomeType, taxableIncome);
  };

  // Helper function to calculate tax based on the given income type
  const calculateTaxForIncome = (effectiveIncomeType, taxableIncome) => {
    const brackets = taxBrackets[effectiveIncomeType === 'business' ? 'business' : filingStatus];
    let tax = 0;

    for (let i = 0; i < brackets.length; i++) {
      const { rate, min, max } = brackets[i];
      if (taxableIncome > min) {
        const taxableAtThisRate = Math.min(taxableIncome, max) - min;
        tax += taxableAtThisRate * rate;
      } else {
        break;
      }
    }

    setTaxOwed(tax.toFixed(2));
    setLoading(false);
  };

  const renderTaxResult = () => {
    if (loading) {
      return <InfinitySpin color="#4fa94d" />;
    } else if (taxOwed !== null) {
      return (
        <div style={{ textAlign: 'center', color: 'green' }}>
          {incomeType === 'expat' && taxOwed <= '120000' ? (
            <div>
                <div style={{padding:'10px',fontWeight:'bold'}}><b>Estimated Tax Owed: !${taxOwed}</b></div>
              <p>
                In most cases, expats do not need to pay any tax in the U.S. They
                still need to file and report the proper forms in order to reach
                this result.{' '}
                <a href="https://primetaxonline.com/appointments" target="_blank" rel="noopener noreferrer">
                  Contact us
                </a>{' '}
                to learn more.
              </p>
            </div>
          ) : (
            <div>Estimated Tax Owed: ${taxOwed}</div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <Navbar />
      <div id="layoutDefault">
        <div id="layoutDefault_content">
          <main>
            <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
              <div className="page-header-ui-content pt-10">
                <div className="container px-5 text-center">
                  <div className="row gx-5 justify-content-center">
                    <div className="col-lg-8">
                      <h1 className="page-header-ui-title mb-3">US Tax Calculator</h1>
                      <p className="page-header-ui-text">
                        Calculate your estimated federal tax liability quickly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor">
                  <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
              </div>
            </header>
            <section className="bg-light py-10">
              <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                  <div className="col-lg-8 text-center">
                    <h2>Enter Your Information</h2>
                    <p className="lead mb-5">Find out how much tax you owe for the current year.</p>
                  </div>
                </div>
                <div className="row gx-5 mb-4">
                  <div className="col-md-6">
                    <label className="text-dark mb-2" htmlFor="inputIncome">Total Income</label>
                    <input
                      className="form-control py-4"
                      onChange={(e) => setIncome(e.target.value)}
                      type="number"
                      id="inputIncome"
                      placeholder="Enter your total income"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="text-dark mb-2" htmlFor="inputIncomeType">Income Type</label>
                    <select
                      className="form-control py-4"
                      id="inputIncomeType"
                      value={incomeType}
                      onChange={(e) => setIncomeType(e.target.value)}
                    >
                      <option value="individual">Individual</option>
                      <option value="business">Business</option>
                      <option value="expat">Expat</option> {/* New Expat option */}
                    </select>
                  </div>
                </div>
                <div className="row gx-5 mb-4">
                  <div className="col-md-6">
                    <label className="text-dark mb-2" htmlFor="inputFilingStatus">Filing Status</label>
                    <select
                      className="form-control py-4"
                      id="inputFilingStatus"
                      value={filingStatus}
                      onChange={(e) => setFilingStatus(e.target.value)}
                      disabled={incomeType === 'business'} // Disable if income type is business
                    >
                      <option value="single">Single</option>
                      <option value="married">Married Filing Jointly</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="text-dark mb-2" htmlFor="inputDeductions">Deductions</label>
                    <input
                      className="form-control py-4"
                      type="number"
                      onChange={(e) => setDeductions(e.target.value)}
                      id="inputDeductions"
                      placeholder="Enter total deductions"
                    />
                  </div>
                </div>
                <div className="row gx-5 mb-4">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-primary px-5 py-3"
                      onClick={calculateTax}
                      type="button"
                    >
                      Calculate Tax
                    </button>
                  </div>
                </div>
                <div className="row gx-5 mb-4">
                  <div className="col-md-12">
                    {renderTaxResult()}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Calculator;
