import Navbar from './NavbarES';
import  Footer  from './FooterES';
import Wpp from '../Wpp';

function Services() {
    return (
        <>
            <Navbar />
            <div id="layoutDefault">
                <div id="layoutDefault_content">
                    <main>
                        <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                            <div class="page-header-ui-content pt-10">
                                <div class="container px-5 text-center">
                                    <div class="row gx-5 justify-content-center">
                                        <div class="col-lg-8">
                                            <h1 class="page-header-ui-title mb-3">Nuestros servicios</h1>
                                            <p class="page-header-ui-text">Nuestros productos, diseñados especialmente para vos.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="svg-border-rounded text-light">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                            </div>
                        </header>
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Impuestos</h2>
                                </div>
                                <div class="row gx-5">
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">No Residente</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            249
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Declaración jurada impositiva no residente (Formulario 1040NR)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Formulario W2</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Intereses y dividendos</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Ingresos por alquileres</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Work & Travel</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Corrección de declaración previa (Formulario 1040X)</span>
                                        </li>
                                        <li class="pricing-list-item" style={{padding:'15px'}}>
                                            <span class="text-dark">*Si recibiste los pagos de coronavirus (Stimulus check) contacta a uno de nuestros expertos para poder corregir tus declaraciones. Es importante destacar que dichos pagos no corresponden a quienes no son residentes.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Residente</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            349
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Declaración jurada impositiva no residente (Formulario 1040NR)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Formulario W2</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Intereses y dividendos</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Ingresos por alquileres</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Compra/ venta de acciones</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Reclama tu Covid “Stimulus check”</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                    <div class="col-lg-4">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Expatriado</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            449
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Optimización de exclusión por ingresos foráneos y crédito impositivo.</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Reporte de cuentas foráneas que exceden los $10,000 acumulados durante el año (Adicional de $100)</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                </div>
                                <div class="mt-5 text-center"><a class="btn btn-primary fw-500" href="/appointments">Empezar</a></div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Inversiones</h2>
                                    <p class="lead">Consultoría GRATUITA de inversiones</p>
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-lg-6 mb-5 mb-lg-0">
                                        <div class="card pricing">
                                            <div class="card-body p-5">
                                                <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Gratis</div>
                                                <div class="pricing-price">
                                                    <sup>Consultoría</sup>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Consultoría de inversiones</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Invertí en el mercado financiero</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Asesoría de como tramitar la VISA inversionista</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Planes de inversión de corto, mediano y largo plazo</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Pequeñas inversiones de capital</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Grandes inversiones de capital</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="card-footer px-5">
                                                <a class="d-flex align-items-center justify-content-between" href="/appointments">
                                                    Agenda tu llamada ya!
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card pricing pricing-right bg-gray-800">
                                            <div class="card-body p-5">
                                                <div class="badge bg-transparent-light badge-marketing rounded-pill badge-sm">Personalizado</div>
                                                <p class="card-text py-5">¿Necesitas un plan personalizado? Contactanos directamente y podemos trabajar juntos para diseñar un plan que se amolde a tus necesidades especificas.</p>
                                            </div>
                                            <div class="card-footer bg-gray-900 px-5">
                                                <a class="text-white-50 d-flex align-items-center justify-content-between" href="/appointments">
                                                    Contacta a un experto
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Empresa</h2>
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing pricing-left">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Admin</div>
                                                    <div class="pricing-price">
                                                        <div className='starts_at_price'></div>
                                                        <sup>$</sup>
                                                        199
                                                        <span class="pricing-price-period">/mes</span>
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Contabilidad</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Desarrollo Web/ cloud de pagina y aplicaciones</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Marketing</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Consulta gratis</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing py-4 z-1">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Impuestos de empresa</div>
                                                    <div class="pricing-price">
                                                        <div className='starts_at_price'></div>
                                                        <sup>$</sup>
                                                        549
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Declaración jurada corporativa (Formulario 1120)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Declaración jurada de sociedad (Formulario 1065)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Declaración jurada de Corporación S (Formulario 1120-S)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Estructura y organización optima</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Declaración informacional de una corporación con al menos 25% de los dueños foráneos (Formulario 5472)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Declaración informacional de personas Estado Unidenses dueñas de corporaciones foráneas.</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Formulario K-1</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Consulta gratis</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing pricing-right">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-secondary-soft rounded-pill badge-marketing badge-sm text-secondary">LLC</div>
                                                    <div class="pricing-price">
                                                        <sup>$</sup>
                                                        1,149
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Abrí tu empresa (LLC)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Abrí tu cuenta bancaria</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Diseño de artículos de incorporación</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Dirección y representante en Wyoming o Delaware</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Consulta gratis</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Tecnologia</h2>
                                    <p class="lead">Todo lo que tu negocio necesita en el mundo TI</p>
                                </div>
                                <div class="row g-0 align-items-center ">
                                    <div class="col-lg-6 mb-5 mb-lg-0">
                                        <div class="card pricing">
                                            <div class="card-body p-5">
                                                <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Free consulting</div>
                                                <div class="pricing-price">
                                                    <sup>Nuestros servicios de TI</sup>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Crea y registra tu marca y logo</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Registra tu dominio</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Cree y aloje su aplicación/web en línea</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Gestión de base de datos</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Soporte tecnico</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">E-commerce</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Seguridad cibernética</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Marketing online</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Email newsletter</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="card-footer px-5">
                                                <a class="d-flex align-items-center justify-content-between" href="/appointments">
                                                    Empecemos!
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card pricing pricing-right bg-gray-800 ">
                                            <div class="card-body p-5">
                                                <div class="badge bg-transparent-light badge-marketing rounded-pill badge-sm">¿Necesita contratar profesionales de TI?</div>
                                                <p class="card-text py-5">Ayudamos a formar equipos con profesionales de TI para trabajar para su negocio desde nuestra oficina en Argentina.</p>
                                            </div>
                                            <div class="card-footer bg-gray-900 px-5">
                                                <a class="text-white-50 d-flex align-items-center justify-content-between" href="/appointments">
                                                    Contactarse con un experto
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light pt-10">
                            <div class="container px-5">
                                <div class="row gx-5 align-items-center mb-10">
                                    <div class="col-lg-6">
                                        <div class="h4 fw-normal">Preguntanos lo que quieras</div>
                                    </div>
                                    <div class="col-lg-6 text-lg-end">
                                        <a class="btn btn-primary fw-500 me-3 my-2" href="/contact">Contactanos</a>
                                        <a class="btn btn-white fw-500 shadow my-2" href="/appointments">Agenda una llamada gratuita</a>
                                    </div>
                                </div>
                                <div class="row gx-5">
                                    <div class="col-lg-6 z-1 mb-5 mb-lg-n5">
                                        <a class="card text-decoration-none h-100 lift" href="#!">
                                            <div class="card-body py-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="ms-4">
                                                        <h5 class="text-primary">Trabaja más inteligentemente</h5>
                                                        <p class="card-text text-gray-600">Ahorra tiempo y dinero con nuestros productos. Enfocate en hacer 100% lo que mas te gusta.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-6 z-1 mb-10 mb-lg-n5">
                                        <a class="card text-decoration-none h-100 lift" href="#!">
                                            <div class="card-body py-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="ms-4">
                                                        <h5 class="text-secondary">Personalizado solo para vos</h5>
                                                        <p class="card-text text-gray-600">Contanos exactamente lo que necesitas y diseñaremos una solución precisamente para vos.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="svg-border-waves text-dark">

                            </div>
                        </section>
                    </main>
                </div>
                <Wpp />
                <Footer />
            </div>
        </>
    )
}

export default Services;