import  Navbar  from './Navbar';
import  Footer  from './Footer';
import  Team  from './Team';
import { Carousel } from 'react-bootstrap';
import gonzalo from '../img/illustrations/profiles/gjs_r.jpg';
import cameron from '../img/illustrations/profiles/cameron.jpg';
import sjs from '../img/illustrations/profiles/sjs.JPG';
import jp from '../img/illustrations/profiles/jp.jpg';
import tomas from '../img/illustrations/profiles/tomas.jpg';
import nicolas from '../img/illustrations/profiles/nicolas.jpg';
import team_1 from '../img/illustrations/profiles/team_1.jpg';
import team_2 from '../img/illustrations/profiles/team_2.jpg';
import team_3 from '../img/illustrations/profiles/team_3.jpg';
import team_4 from '../img/illustrations/profiles/team_4.jpg';
import brightsite from '../img/logos/brightsite_logo_r.png';

import Wpp from './Wpp';

function Outsourcing(){
    return(
<>
    <Navbar />
    <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">Outsourcing Program</h1>
                                        <p class="page-header-ui-text">Save time and money outsourcing your services with us</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-white py-10">
                        <div class="container px-5">
                            <div class="row gx-5 justify-content-center">
                                <div class="col-lg-10">
                                <h1>Outsourcing can provide several benefits to businesses:</h1>
  <ol>
    <li><strong>Cost Savings:</strong> One of the most significant advantages of outsourcing is cost savings. By outsourcing tasks to countries with lower labor costs or specialized expertise, businesses can reduce operational expenses related to salaries, benefits, infrastructure, and overhead.</li>
    <li><strong>Focus on Core Activities:</strong> Outsourcing non-core functions allows businesses to focus on their core competencies and strategic goals. Instead of spending time and resources on peripheral tasks, companies can allocate their resources more efficiently to areas where they can add the most value and differentiate themselves in the market.</li>
    <li><strong>Access to Specialized Skills:</strong> Outsourcing enables businesses to tap into a global talent pool and access specialized skills that may not be available in-house. Whether it's IT support, customer service, or manufacturing, outsourcing allows companies to leverage expertise and experience that they may not have internally.</li>
    <li><strong>Flexibility and Scalability:</strong> Outsourcing provides businesses with the flexibility to scale operations up or down quickly in response to changing market conditions, seasonal fluctuations, or growth opportunities. This scalability can help companies adapt more effectively to market demands without incurring significant fixed costs.</li>
    <li><strong>Risk Mitigation:</strong> Outsourcing can help mitigate certain risks associated with business operations. For example, outsourcing certain functions to multiple vendors or locations can reduce dependency on a single provider or market. Additionally, outsourcing can help businesses navigate regulatory compliance and geopolitical risks by leveraging the expertise of outsourcing partners.</li>
    <li><strong>Faster Time to Market:</strong> Outsourcing can accelerate time to market for products and services by leveraging the resources and capabilities of outsourcing partners. By delegating tasks to external experts, businesses can streamline processes, reduce lead times, and bring innovations to market more quickly.</li>
    <li><strong>Improved Focus on Innovation:</strong> By offloading routine tasks and administrative burdens, outsourcing allows businesses to free up internal resources and talent for more innovative endeavors. This can lead to the development of new products, services, and processes that drive competitiveness and growth.</li>
  </ol>
  <p>Overall, outsourcing can be a strategic tool for businesses to enhance efficiency, reduce costs, mitigate risks, and drive innovation, ultimately enabling them to stay competitive in today's dynamic business environment.</p>
                             
                                    <p>Special prices for our partners on all of our services</p>
                                    <p>Contact us to discuss what service best suits your business</p>

                                    <div>
                                    <div class="col-lg-6 text-lg-end" style={{margin:'auto'}}>
                                        <a class="btn btn-primary fw-500 me-3 my-2" href="/contact">Contact Us</a>
                                        <a class="btn btn-white fw-500 shadow my-2" href="/appointments">Schedule a free call</a>
                                    </div>
                                    </div>
                                    <hr class="my-5" />
                                    <h4 class="mb-4">
                                        Our Experience
                                    </h4>
                                    <p class="lead">We have prepared more than 4,000 personal U.S Income tax returns (Form 1040) and have helped over 300 companies get their paperwork and books in order. We have been in the industry for +8 years and have been building strong and durable relationships with our clients. We’ve assisted clients worldwide in +15 countries.</p>
                                   
                                    <hr class="my-5" />
                                    <h4>Some of the countries where our clients are located.</h4>
                                        <p class="lead">
                                            • United States
                                            • United Kingdom
                                            • Spain
                                            • Greece
                                            • Czech Republic
                                            • Chile
                                            • Uruguay
                                            • Colombia
                                            • Costa Rica
                                            • Argentina
                                            • Mexico
                                            • Vietnam
                                            • Peru
                                            • Paraguay
                                            • Russia
                                            .
                                        </p>
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
    </>
    )
}

export default Outsourcing;