import '../App.css';
import imgSvg from '../img/illustrations/creativity.svg';
import logo from '../img/logos/primetaxlogo.png';

function Header() {
    return (
        <header className="page-header-ui page-header-light py-lg-5 bg-light" style={{marginTop:'100px'}}>
            <div className="page-header-ui-content">
                <div className="container px-5">
                    <div className="row gx-5 align-items-center" style={{ textAlign: 'left' }}>
                        <div className="col-lg-7">
                            <h1 className="page-header-ui-title">Stress-free U.S. Accounting solutions.</h1>
                            <p className="page-header-ui-text mb-5">Tax, business and investment advisory tailored just for you. We will solve whatever issue it is that is keeping you up at night.</p>
                            <a className="btn btn-primary fw-500 me-2" href="/appointments">
                                Book a free call now
                                <i className="ms-2" data-feather="arrow-right"></i>
                            </a>
                            <a className="btn" href="/services">Our services</a>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block"><img className="img-fluid ps-xl-5" src={imgSvg} /></div>
                    </div>
                </div>
            </div>
            <div className="svg-border-rounded text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
            </div>
        </header>
    )
}

export default Header;