import { useEffect, useState } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function SubscribeModalES(){

  const api_url = 'https://primetaxonline.com:2053';

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const [subscribe, setSubscribe] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 7000);
  }, [0])

  const sendSubscribe = () => {
    setShow(false);
    axios({
      method:'POST',
      url:api_url+'/api/subscribe',
      data:{
          email:email
      }
  }).then(res => {
  }).catch((error) => { // error is handled in catch block
    if (error.response) { // status code out of the range of 2xx
      console.log("Data :" , error.response.data);
      console.log("Status :" + error.response.status);
    } else if (error.request) { // The request was made but no response was received
      console.log(error.request);
    } else {// Error on setting up the request
      console.log('Error', error.message);
    }
  });
  }

    return(
        <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div><Modal.Title>Suscribirse!</Modal.Title></div>
        </Modal.Header>
        <Modal.Body>
          <div>Inscríbete a nuestro boletín GRATIS y mantente actualizado con las últimas noticias sobre impuestos, economía, inversiones y estilo de vida. Un invitado diferente cada semana y trucos para tu negocio.</div>
        </Modal.Body>
          <div style={{textAlign:'center',padding:'20px', border:'none'}}>
            @ <input type="text" placeholder="Correo Electronico" onChange={e => setEmail(e.target.value)} />
            <div style={{padding:'5px'}}>
            <Button variant="primary" disabled={!email} onClick={sendSubscribe}>
            Suscribirse
            </Button>
            </div>
            </div>
      </Modal>
        </>
    )
}

export default SubscribeModalES;