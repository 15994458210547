import '../../index.css';
import logo from '../../img/logos/primetaxlogo.png';
import { Menu } from 'react-feather';
import arg_flag_icon from '../../img/illustrations/flags/arg_flag_icon.png';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';

function Navbar() {

    const cookies = new Cookies();
    const [selectedLang, setSelectedLang] = useState("selected_en");

    const change_lang = (lang) => {
        cookies.set('lang', lang, { path: '/' });
        window.location.reload();
    }

    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" style={{boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'}}>
        <a href="/"><img height={55} width={55} style={{marginLeft:'15px'}} src='https://primetaxonline.com/img/logos/primetaxlogo3.png' /></a><b style={{padding:'5px'}}>Primetaxonline</b>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" style={{marginRight:'15px'}}>
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText" style={{margin:'auto', textAlign:'center'}}>
  <ul className="navbar-nav ms-auto me-lg-5">
                        <li className="nav-item"><a className="nav-link" href="/">Inicio</a></li>
                        <li className="nav-item"><a className="nav-link" href="/services">Servicios</a></li>

                        <li className="nav-item dropdown dropdown-xl no-caret">
                            <a className="nav-link" id="navbarDropdownPages" href="/about" role="button" aria-haspopup="true" aria-expanded="false">
                                Nosotros
                            </a>
                        </li>
                        <li className="nav-item dropdown no-caret">
                            <a className="nav-link " id="navbarDropdownDocs" href="/contact" role="button"  aria-expanded="false">
                                Contactenos
                            </a>
                        </li>
                        <li className="nav-item dropdown no-caret" style={{margin:'auto',padding:'5px'}}>
                            <select className="nav-link" onChange={e => change_lang(e.target.value)}>
                                <option value={"selected_en"}>EN</option>
                                <option value={"selected_es"}>ES</option>
                            </select>
                        </li>
                    </ul>
                    <a className="btn fw-500 ms-lg-4 btn-primary" href="/appointments">
                        Agendar llamada gratis
                        <i className="ms-2" data-feather="arrow-right"></i>
                    </a>
  </div>
</nav>
    )
}

export default Navbar;