import { useState } from "react";
import '../App.css';
import wpp_png from '../img/illustrations/wpp.png';

const Wpp = () => {

        return(
            <div className="wpp">
                <a href="https://wa.me/+541136555405"><img src={wpp_png} className="wpp_img" /></a>
            </div>
        )
}

export default Wpp;