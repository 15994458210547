import  Navbar  from './Navbar';
import  Footer  from './Footer';
import { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios';
import Wpp from './Wpp';


function Contact(){

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const api_url = 'https://primetaxonline.com:2053';

    function SendEmail() {

        

        if (loading == false && emailSent == false) {
            return (
                <button class="btn btn-primary mt-4" type="submit" onClick={e => send()} disabled={!name || !email || !message}>Send</button>
            )
        } else if(emailSent == true && loading == false){
            return(
                <div style={{textAlign:'center', color:'green'}}>Your email was sent correctly. One of our representatives will get in touch with you soon. Thank you.</div>
            )
        }
         else if(loading == true) {
            return (
                <InfinitySpin color="#4fa94d" />
            )
        }

    }

    const send = () => {
        setLoading(true);

        axios({
            method:'POST',
            url:api_url+'/api/send_contact_email',
            data:{
                name:name,
                email:email,
                message:message
            }
        }).then(res => {
            console.log("Email status "+res.status);
            if(res.status = 200){
                setEmailSent(true);
                setLoading(false);
                setMessage('');
            }else{
                setLoading(false);
                alert("There was a problem sending your email. Please try again. Thank you.");
            }
            
        });

    }

    return(
        <>
        <Navbar />
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">Contact us</h1>
                                        <p class="page-header-ui-text">Have questions? We're here to help!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-light">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-light py-10">
                        <div class="container px-5">
                            
                            <div class="row gx-5 justify-content-center">
                                <div class="col-lg-8 text-center">
                                    <h2>Can't find the answer you need?</h2>
                                    <p class="lead mb-5">Contact us and we'll get back to you as soon as possible.</p>
                                </div>
                            </div>
                            <div class="row gx-5 align-items-center mb-10">
                                <div class="col-lg-4 text-center mb-5 mb-lg-0">
                                    <div class="section-preheading">Message Us</div>
                                    <a href="https://wa.me/+541136555405">Whatsapp</a>
                                </div>
                                <div class="col-lg-4 text-center mb-5 mb-lg-0">
                                    <div class="section-preheading">Call Anytime</div>
                                    <a href="tel:+54-11-3655-5405">+54 11 3655-5405</a>
                                </div>
                                <div class="col-lg-4 text-center">
                                    <div class="section-preheading">Email Us</div>
                                    <a href="mailto:info@primetaxonline.com">info@primetaxonline.com</a>
                                </div>
                            </div>
                            
                                <div class="row gx-5 mb-4">
                                    <div class="col-md-6">
                                        <label class="text-dark mb-2" for="inputName">Full name</label>
                                        <input class="form-control py-4" onChange={e => setName(e.target.value)} id="inputName" type="text" placeholder="Full name" />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="text-dark mb-2" for="inputEmail">Email</label>
                                        <input class="form-control py-4" id="inputEmail" onChange={e => setEmail(e.target.value)} type="email" placeholder="name@example.com" />
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="text-dark mb-2" for="inputMessage">Message</label>
                                    <textarea class="form-control py-3" id="inputMessage" type="text" value={message} onChange={e => setMessage(e.target.value)} placeholder="Enter your message..." rows="4"></textarea>
                                </div>
                                <div class="text-center"><SendEmail /></div>
                            
                        </div>
                        <div class="svg-border-rounded text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
        </>
    )
}

export default Contact;